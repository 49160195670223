import React, { useState } from "react";
import { ApiHook } from "../../hooks/apiHook";
import ChangePasswordModal from "../../components/Common/modals/ChangePasswordModal";
import { useNavigate } from "react-router";

export default function Password() {
  const Profile = ApiHook.CallProfile();
  const [showPasswordModal, setShowPasswordModal] = useState(true);
  const navigate = useNavigate();
  const handleClosePasswordModal = () => {
    setShowPasswordModal(!showPasswordModal);
    navigate(-1);
  };
  return (
    <ChangePasswordModal
      showModal={showPasswordModal}
      onHide={handleClosePasswordModal}
      passwordPolicy={Profile?.data?.passwordPolicy}
    />
  );
}
