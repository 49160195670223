import { MailServices } from "../../services/mailbox/mailbox";


export const Inboxes = async (page,limit) => {
    try {
        const response = await MailServices.getInboxes(page,limit);
        return response
    } catch (error) {
        console.log(error.message);
    }
}

export const SingleMail = async (id) => {
    try {
        const response = await MailServices.getSingleMail(id);
        return response
    } catch (error) {
        console.log(error.message);
    }
}

export const ReplyMail = async (data) => {
    try {
        const response = await MailServices.replyMail(data);
        return response
    } catch (error) {
        console.log(error.message);
    }
}