import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const MailServices = {
    getInboxes: async (page, limit) => {
        return callApi(`inbox?page=${page}&perPage=${limit}`)
    },
    getSingleMail: async (id) => {
        return callApi(`view-single-mail?mailId=${id}`)
    },
    replyMail: async (data) => {
        return API.post('reply-to-mail', JSON.stringify(data))
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    }
}