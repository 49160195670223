import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  AppLayout,
  DashboardDetails,
  DashboardExpenses,
  DashboardTiles,
  DashboardUserProfile,
  GetGraph,
  NotificationData,
  PackageOverview,
  RankOverview,
  TopRecruiters,
} from "../store/actions/dashboardAction";
import {
  AdditionalDetails,
  BankDetailsUpdate,
  changePassword,
  changeTransactionPassword,
  ContactDetailsUpdate,
  deleteKycFile,
  deleteProfileAvatar,
  fetchProfile,
  KycDetails,
  KycUpload,
  loginUser,
  logout,
  PaymentDetails,
  PersonalDetailsUpdate,
  updateCurrency,
  updateLanguage,
  updateProfileAvatar,
} from "../store/actions/userAction";
import {
  setAppLayout,
  setDashboardOne,
} from "../store/reducers/dashboardReducer";
import {
  setConversionFactors,
  setIsAuthenticated,
  setLoginResponse,
  setProfile,
  setSelectedCurrency,
  setSelectedLanguage,
  updateBank,
  updateContact,
} from "../store/reducers/userReducer";
import { useNavigate } from "react-router";
import {
  GenealogyActions,
  SponserTreeActions,
  TreeViewActions,
  downlineMembersActions,
  ReferralMembersActions,
} from "../store/actions/treeAction";
import {
  enableBackToParent,
  enableSponserBackToParent,
  setGenealogyTreeList,
  setSponserTreeList,
  setTreeViewList,
  updateSponserTreeList,
  updateTreeNode,
  updateTreeViewList,
  updateUnilevelGenealogyTree,
} from "../store/reducers/treeReducer";
import {
  EwalletBalance,
  FundTransfer,
  MyEarnings,
  PurchaseHistory,
  Statement,
  Tiles,
  TransferHistory,
} from "../store/actions/ewalletAction";
import {
  PayoutRequestApi,
  PayoutRequestDetails,
  PayoutTiles,
  TilesAndDetails,
} from "../store/actions/payoutAction";
import {
  EpinListAndTiles,
  EpinPendingRequest,
  EpinPurchase,
  EpinRefund,
  EpinRequest,
  EpinTiles,
  EpinTransfer,
  EpinTransferHistory,
  PurchasedEpinList,
} from "../store/actions/epinAction";
import {
  callCheckIsPresent,
  callDemoVisitorData,
  callResendOtp,
  callVerifyOtp,
} from "../store/actions/demoVisitorAction";
import { toast } from "react-toastify";
import {
  RegisterFields,
  RegisterFieldCheck,
  TranssPassCheck,
  CreateRegisterLink,
  CreateStoreLink,
  RegisterUser,
  BankUpload,
  LetterPreview,
} from "../store/actions/registerAction";
import { useTranslation } from "react-i18next";
import {
  getDownloadMaterials,
  getFaqs,
  getLeads,
  getNews,
  getNewsById,
  getReplicaBanner,
  searchLeads,
  updateLead,
  uploadReplicaBanner,
} from "../store/actions/toolsAction";
import {
  DefaultAddressChange,
  PlaceRepurchaseOrder,
  ProductDetails,
  addAddress,
  addToCart,
  callPaymentMethods,
  decrementCartItem,
  getAddress,
  getCartItems,
  getRepurchaseItems,
  removeAddress,
  removeCartItem,
} from "../store/actions/shopping";
import {
  ReplicaBankUploadReceipt,
  ReplicaHome,
  ReplicaRegisterFields,
  ReplicaRegisterPost,
  getApiKey,
  replicaFieldCheck,
  getAdminReplica,
} from "../store/actions/replicaAction";
import {
  setCompanyDetails,
  setRegisterLink,
  setTermsAndPolicy,
} from "../store/reducers/replica";
import {
  AddLcpLead,
  getCompanyDetails,
  getReplicaApi,
} from "../store/actions/lcp";
import { UpgradeActions } from "../store/actions/upgradeAction";
import { RenewActions } from "../store/actions/renewAction";
import {
  ChangeForgotPassword,
  ForgotPassword,
  VerifyForgotPassword,
} from "../store/actions/authAction";
import { Inboxes, ReplyMail, SingleMail } from "../store/actions/mailAction";

export const ApiHook = {
  // ---------------------------------------- Login -----------------------------------------

  CallLoginUser: () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const response = useMutation((credentials) => loginUser(credentials), {
      onSuccess: (response) => {
        if (response.status) {
          dispatch(setIsAuthenticated(true));
          dispatch(setLoginResponse(response));
          navigate("/dashboard", { replace: true });
        }
      },
    });
    return response;
  },
  CallLogout: () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const response = useMutation(() => logout(), {
      onSuccess: (data) => {
        if (data.status) {
          dispatch(setLoginResponse(null));
          dispatch(setIsAuthenticated(false));
          localStorage.clear();
          navigate("/login");
        }
      },
    });
    return response;
  },
  CallForgotPassword: () => {
    const response = useMutation((data) => ForgotPassword(data));
    return response;
  },
  CallVerifyForgotPassword: (data) => {
    const response = useQuery({
      queryKey: ["verify-forgotPassword"],
      queryFn: () => VerifyForgotPassword(data),
    });
    return response;
  },
  CallChangeForgotPassword: () => {
    const response = useMutation((data) => ChangeForgotPassword(data));
    return response;
  },
  CallCheckIsPresent: () => {
    const response = useQuery({
      queryKey: ["check-is-present"],
      queryFn: callCheckIsPresent,
    });
    return response;
  },
  CallAddDemoVisitor: () => {
    const response = useMutation((data) => callDemoVisitorData(data));
    return response;
  },
  CallResendOtp: () => {
    const response = useMutation((data) => callResendOtp(data));
    return response;
  },
  CallVerifyOtp: () => {
    const response = useMutation((data) => callVerifyOtp(data));
    return response;
  },
  // ---------------------------------------- Dashboard -----------------------------------------

  CallAppLayout: () => {
    const dispatch = useDispatch();
    const defaultCurrency = useSelector(
      (state) => state.user?.loginResponse?.defaultCurrency
    );
    const response = useQuery({
      queryKey: ["app-layout"],
      queryFn: AppLayout,
      onSuccess: (data) => {
        dispatch(setAppLayout(data));
        dispatch(
          setSelectedCurrency(
            data?.user?.defaultCurrency ? data?.user?.defaultCurrency : null
          )
        );
        dispatch(
          setSelectedLanguage(
            data?.user?.defaultLang ? data?.user?.defaultLang : null
          )
        );
        dispatch(
          setConversionFactors({
            currencies: data?.currencies,
            selectedCurrency: data?.user?.defaultCurrency
              ? data?.user?.defaultCurrency
              : JSON.parse(defaultCurrency),
            defaultCurrency: JSON.parse(defaultCurrency),
          })
        );
      },
    });
    return response;
  },
  CallDashboardRight: (dashboardCheck, setDashboardCheck) => {
    const response = useQuery({
      queryKey: ["dashboard-user-profile"],
      queryFn: DashboardUserProfile,
      onSuccess: () => {
        setDashboardCheck(false);
      },
      enabled: !!dashboardCheck,
    });
    return response;
  },
  CallDashboardTiles: () => {
    const response = useQuery({
      queryKey: ["dashboard-tiles"],
      queryFn: DashboardTiles,
    });
    return response;
  },
  CallGraphFilter: (selectedFilter) => {
    const dispatch = useDispatch();
    const response = useQuery({
      queryKey: ["get-graph", selectedFilter],
      queryFn: () => GetGraph(selectedFilter),
      onSuccess: (data) => {
        dispatch(setDashboardOne(data));
      },
    });
    return response;
  },
  CallCurrencyUpdation: ({ selectedCurrency }) => {
    const dispatch = useDispatch();
    const mutation = useMutation((id) => updateCurrency(id), {
      onSuccess: () => {
        dispatch(setSelectedCurrency(selectedCurrency));
      },
    });
    return mutation;
  },
  CallLanguageUpdation: ({ selectedLanguage }) => {
    const dispatch = useDispatch();
    const mutation = useMutation((id) => updateLanguage(id), {
      onSuccess: () => {
        dispatch(setSelectedLanguage(selectedLanguage));
      },
    });
    return mutation;
  },
  CallNotificationData: (notificationCheck, setNotificationCheck) => {
    const response = useQuery({
      queryKey: ["notification-data"],
      queryFn: NotificationData,
      onSuccess: () => {
        setNotificationCheck(false);
      },
      enabled: !!notificationCheck,
    });
    return response;
  },
  CallDashboardDetails: () => {
    const response = useQuery({
      queryKey: ["dashboard-details"],
      queryFn: DashboardDetails,
    });
    return response;
  },
  CallTopRecruiters: (recruitersCheck, setRecruitersCheck) => {
    const response = useQuery({
      queryKey: ["top-recruiters"],
      queryFn: TopRecruiters,
      onSuccess: () => {
        setRecruitersCheck(false);
      },
      enabled: !!recruitersCheck,
    });
    return response;
  },
  CallPackageOverview: (packageCheck, setPackageCheck) => {
    const response = useQuery({
      queryKey: ["package-overview"],
      queryFn: PackageOverview,
      onSuccess: () => {
        setPackageCheck(false);
      },
      enabled: !!packageCheck,
    });
    return response;
  },
  CallRankOverview: (rankCheck, setRankCheck) => {
    const response = useQuery({
      queryKey: ["rank-overview"],
      queryFn: RankOverview,
      onSuccess: () => {
        setRankCheck(false);
      },
      enabled: !!rankCheck,
    });
    return response;
  },
  CallDahboardExpenses: (expenseCheck, setExpenseCheck) => {
    const response = useQuery({
      queryKey: ["dashboard-expenses"],
      queryFn: DashboardExpenses,
      onSuccess: () => {
        setExpenseCheck(false);
      },
      enabled: !!expenseCheck,
    });
    return response;
  },
  // ---------------------------------------- Profile -----------------------------------------

  CallProfile: () => {
    const dispatch = useDispatch();
    const response = useQuery({
      queryKey: ["profile"],
      queryFn: fetchProfile,
      onSuccess: (data) => {
        dispatch(setProfile(data));
      },
    });
    return response;
  },
  CallUpdatePersonalDetails: () => {
    const mutation = useMutation((profileDetails) =>
      PersonalDetailsUpdate(profileDetails)
    );
    return mutation;
  },
  CallUpdateContactDetails: (contactDetails) => {
    const dispatch = useDispatch();
    const mutation = useMutation(
      (contactDetails) => ContactDetailsUpdate(contactDetails),
      {
        onSuccess: (response) => {
          if (response.status) {
            dispatch(
              updateContact({
                contactDetails: contactDetails,
              })
            );
          }
        },
      }
    );
    return mutation;
  },

  CallUpdateBankDetails: (bankDetails) => {
    const dispatch = useDispatch();
    const mutation = useMutation(
      (bankDetails) => BankDetailsUpdate(bankDetails),
      {
        onSuccess: (response) => {
          if (response.status) {
            dispatch(
              updateBank({
                bankDetails: bankDetails,
              })
            );
          }
        },
      }
    );
    return mutation;
  },
  CallUpdateProfilePicture: () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const mutation = useMutation(
      (profilePic) => updateProfileAvatar(profilePic),
      {
        onSuccess: (response) => {
          if (response.status) {
            toast.success(t(response?.data?.message));
            queryClient.invalidateQueries({ queryKey: ["profile"] });
            queryClient.invalidateQueries({ queryKey: ["app-layout"] });
          } else if (response?.data?.code) {
            toast.error(t(response?.data?.description));
          } else {
            toast.error(t(response?.data?.message));
          }
        },
      }
    );
    return mutation;
  },
  CallAdditionalDetails: () => {
    const response = useMutation((additionalDetails) =>
      AdditionalDetails(additionalDetails)
    );
    return response;
  },
  CallPaymentDetails: () => {
    const response = useMutation((paymentDetails) =>
      PaymentDetails(paymentDetails)
    );
    return response;
  },
  CallKycDetails: () => {
    const response = useQuery({
      queryKey: ["kyc-details"],
      queryFn: KycDetails,
    });
    return response;
  },
  CallKycUploads: () => {
    const response = useMutation((files) => KycUpload(files));
    return response;
  },
  CallDeleteKycFiles: () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const response = useMutation((filesId) => deleteKycFile(filesId), {
      onSuccess: (response) => {
        if (response.status) {
          queryClient.invalidateQueries({ queryKey: ["kyc-details"] });
          toast.success(t(response?.data));
        }
      },
    });
    return response;
  },
  CallDeleteProfileAvatar: () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const response = useMutation(deleteProfileAvatar, {
      onSuccess: (response) => {
        if (response.status) {
          queryClient.invalidateQueries({ queryKey: ["profile"] });
          queryClient.invalidateQueries({ queryKey: ["app-layout"] });
          toast.success(t(response?.data));
        }
      },
    });
    return response;
  },
  CallChangePassword: () => {
    const response = useMutation((body) => changePassword(body));
    return response;
  },
  CallChangeTransactionPassword: () => {
    const response = useMutation((body) => changeTransactionPassword(body));
    return response;
  },

  // ---------------------------------------- Ewallet -----------------------------------------

  CallEwalletTiles: () => {
    const response = useQuery({
      queryKey: ["ewallet-tiles"],
      queryFn: Tiles,
    });
    return response;
  },
  CallEwalletStatement: (page, itemsPerPage, selectStatement) => {
    const response = useQuery({
      queryKey: ["statement", page, itemsPerPage, selectStatement],
      queryFn: () => Statement(page, itemsPerPage),
    });
    return response;
  },

  CallTransferHistory: (
    page,
    itemsPerPage,
    selectedPageCheck,
    setSelectedPageCheck,
    selectedCategory,
    startDate = "",
    endDate = ""
  ) => {
    const response = useQuery({
      queryKey: ["transfer-history"],
      queryFn: () =>
        TransferHistory(
          page,
          itemsPerPage,
          selectedCategory,
          startDate,
          endDate
        ),
      onSuccess: () => {
        setSelectedPageCheck(false);
      },
      enabled: !!selectedPageCheck,
    });
    return response;
  },
  CallPurchaseHistory: (
    page,
    itemsPerPage,
    selectedPageCheck,
    setSelectedPageCheck
  ) => {
    const response = useQuery({
      queryKey: ["purchase-history"],
      queryFn: () => PurchaseHistory(page, itemsPerPage),
      onSuccess: () => {
        setSelectedPageCheck(false);
      },
      enabled: !!selectedPageCheck,
    });
    return response;
  },
  CallMyEarnings: (
    page,
    itemsPerPage,
    selectedPageCheck,
    setSelectedPageCheck,
    selectedCategory,
    startDate = "",
    endDate = ""
  ) => {
    const response = useQuery({
      queryKey: ["my-earnings"],
      queryFn: () =>
        MyEarnings(page, itemsPerPage, selectedCategory, startDate, endDate),
      onSuccess: () => {
        setSelectedPageCheck(false);
      },
      // enabled: !!selectedPageCheck,
    });
    return response;
  },
  CallFundTransfer: () => {
    const response = useMutation((data) => FundTransfer(data), {
      onSuccess: (data) => {
        if (data.status === 200) {
          toast.success(data.data.data);
        }
      },
    });
    return response;
  },
  // ---------------------------------------- Payout -----------------------------------------

  CallPayoutDetails: (page, itemsPerPage, type) => {
    const response = useQuery({
      queryKey: ["payout-details", page, itemsPerPage, type],
      queryFn: () => TilesAndDetails(page, itemsPerPage, type),
    });
    return response;
  },
  CallPayoutRequestDetails: () => {
    const response = useQuery({
      queryKey: ["payout-request-details"],
      queryFn: PayoutRequestDetails,
    });
    return response;
  },
  CallPayoutRequest: () => {
    const response = useMutation((data) => PayoutRequestApi(data), {
      onSuccess: (data) => {
        if (data.status === 200) {
          toast.success(data.data.data);
        }
      },
    });
    return response;
  },
  CallPayoutTiles: () => {
    const response = useQuery({
      queryKey: ["payout-tiles"],
      queryFn: PayoutTiles,
    });
    return response;
  },
  // ---------------------------------------- Epin -------------------------------------------

  CallEpinTiles: () => {
    const response = useQuery({
      queryKey: ["epin-tiles"],
      queryFn: EpinTiles,
    });
    return response;
  },
  CallEpinList: (page, perPage, epinStatus) => {
    const response = useQuery({
      queryKey: ["epin-lists", page, perPage],
      queryFn: () => EpinListAndTiles(page, perPage, epinStatus),
    });
    return response;
  },
  CallEpinPendingRequest: (
    page,
    perPage,
    selectedPageCheck,
    setSelectedPageCheck
  ) => {
    const response = useQuery({
      queryKey: ["epin-pending"],
      queryFn: () => EpinPendingRequest(page, perPage),
      onSuccess: () => {
        setSelectedPageCheck(false);
      },
      enabled: !!selectedPageCheck,
    });
    return response;
  },
  CallEpinHistory: (page, perPage, selectedPageCheck, setSelectedPageCheck) => {
    const response = useQuery({
      queryKey: ["epin-history"],
      queryFn: () => EpinTransferHistory(page, perPage),
      onSuccess: () => {
        setSelectedPageCheck(false);
      },
      enabled: !!selectedPageCheck,
    });
    return response;
  },
  CallEpinPurchase: () => {
    const response = useMutation((data) => EpinPurchase(data), {
      onSuccess: (data) => {
        if (data.status === 200) {
          toast.success(data.data.data);
        }
      },
      onError: () => {
        toast.error("Operation failed");
      },
    });
    return response;
  },
  CallEpinRequest: () => {
    const response = useMutation((data) => EpinRequest(data), {
      onSuccess: (data) => {
        if (data.status === 200) {
          toast.success(data.data.data);
        }
      },
    });
    return response;
  },
  CallEpinTransfer: () => {
    const response = useMutation((data) => EpinTransfer(data), {
      onSuccess: (data) => {
        if (data.status === 200) {
          toast.success(data.data.data);
        }
      },
    });
    return response;
  },
  CallEpinRefund: () => {
    const response = useMutation((data) => EpinRefund(data));
    return response;
  },
  CallPurchasedEpinList: () => {
    const response = useQuery({
      queryKey: ["purchased-epin-list"],
      queryFn: () => PurchasedEpinList(),
      onSuccess: (response) => {},
    });
    return response;
  },

  // ---------------------------------------- Tree -----------------------------------------

  CallGenealogyTreeList: (
    selectedUserId = "",
    doubleClickedUser,
    userName = ""
  ) => {
    const dispatch = useDispatch();
    const response = useQuery({
      queryKey: [
        "genealogy-tree-list",
        selectedUserId,
        doubleClickedUser,
        userName,
      ],
      queryFn: () =>
        GenealogyActions.getTreelist(
          selectedUserId ? selectedUserId : doubleClickedUser,
          userName
        ),
      onSuccess: (res) => {
        if (res?.status) {
          if (doubleClickedUser || userName) {
            dispatch(setGenealogyTreeList(res?.data));
            dispatch(enableBackToParent());
          } else if (selectedUserId) {
            dispatch(
              updateTreeNode({
                nodeId: selectedUserId,
                children: res?.data?.children,
              })
            );
          } else {
            dispatch(setGenealogyTreeList(res?.data));
          }
        } else {
          if (res?.data?.code === 1085) {
            toast.error(res?.data?.description);
          }
        }
      },
    });
    return response;
  },

  CallSponsorTreeList: (
    selectedUserId = "",
    doubleClickedUser,
    userName = ""
  ) => {
    const dispatch = useDispatch();
    const response = useQuery({
      queryKey: [
        "sponsor-tree-list-byid",
        doubleClickedUser,
        userName,
        selectedUserId,
      ],
      queryFn: () =>
        SponserTreeActions.getTreelist(
          selectedUserId ? selectedUserId : doubleClickedUser,
          userName
        ),
      onSuccess: (res) => {
        if (res?.status) {
          if (doubleClickedUser || userName) {
            dispatch(setSponserTreeList(res?.data));
            dispatch(enableSponserBackToParent());
          } else if (selectedUserId) {
            dispatch(
              updateSponserTreeList({
                nodeId: selectedUserId,
                children: res?.data?.children,
              })
            );
          } else {
            dispatch(setSponserTreeList(res?.data));
          }
        } else {
          if (res?.data?.code === 1085) {
            toast.error(res?.data?.description);
          }
        }
      },
    });
    return response;
  },

  CallTreeViewList: (selectedUserId) => {
    const dispatch = useDispatch();
    const response = useQuery({
      queryKey: ["tree-view-list", selectedUserId],
      queryFn: () => TreeViewActions.getTreelist(selectedUserId),
      onSuccess: (res) => {
        if (selectedUserId) {
          dispatch(
            updateTreeViewList({
              nodeId: selectedUserId,
              children: res?.data,
            })
          );
        } else {
          dispatch(setTreeViewList(res?.data));
        }
      },
    });
    return response;
  },

  CallUnilevelMore: (data) => {
    const dispatch = useDispatch();
    const response = useQuery({
      queryKey: ["genealogy-unilevel-more", data],
      queryFn: () =>
        GenealogyActions.getUnilevelMore(data?.fatherId, data?.position),
      onSuccess: (res) => {
        if (res.status) {
          dispatch(
            updateUnilevelGenealogyTree({
              fatherId: data?.fatherId,
              position: data?.position,
              newChildren: res?.data,
            })
          );
        }
      },
      enabled: !!data?.fatherId && !!data?.position,
    });
    return response;
  },

  // -------------------------   downlinemember -------------------

  CallDownlineMembers: (level, page, itemsPerPage) => {
    const response = useQuery({
      queryKey: ["downlinemember", level, page, itemsPerPage],
      queryFn: () =>
        downlineMembersActions.getDownlineMembers(level, page, itemsPerPage),
    });
    return response;
  },
  CallDownlineHead: () => {
    const response = useQuery({
      queryKey: ["downlinehead"],
      queryFn: () => downlineMembersActions.getDownlineheaders(),
    });
    return response;
  },

  //---------------------referralmembers---------------

  CallReferralMembers: (level, page, itemsPerPage) => {
    const response = useQuery({
      queryKey: ["referralmembers", level, page, itemsPerPage],
      queryFn: () =>
        ReferralMembersActions.getReferralmembers(level, page, itemsPerPage),
    });
    return response;
  },
  CallReferralHead: () => {
    const response = useQuery({
      queryKey: ["referralhead"],
      queryFn: () => ReferralMembersActions.getRferralHeader(),
    });
    return response;
  },

  // ----------------------------------------- Register ------------------------------------------

  CallRegisterFields: () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const response = useQuery({
      queryKey: ["get-register"],
      queryFn: RegisterFields,
      onSuccess: (res) => {
        if (res?.data?.code) {
          toast.error(t(res?.data?.description));
          navigate("/dashboard");
        }
      },
    });
    return response;
  },
  CallRegisterFieldsCheck: () => {
    const response = useMutation((data) =>
      RegisterFieldCheck(data.field, data.value)
    );
    return response;
  },
  CallTransPasswordCheck: (
    value,
    transPassCheck,
    setTransPassCheck,
    setSubmitButtonActive,
    totalAmount,
    transPassResposne,
    setTransPassResposne
  ) => {
    const navigate = useNavigate();
    const response = useQuery({
      queryKey: ["transPass-check"],
      queryFn: () => TranssPassCheck(value, totalAmount),
      onSuccess: (data) => {
        setTransPassCheck(false);
        if (data.status === true) {
          setTransPassResposne({
            success: data.data,
          });
          setSubmitButtonActive(false);
        } else if (data.code === 1014) {
          setTransPassResposne({
            error: data.description,
          });
        } else if (data.code === 1015) {
          setTransPassResposne({
            error: data.description,
          });
        } else {
          toast.error(data.message);
          navigate("/dashboard");
        }
      },
      enabled: !!transPassCheck,
    });
    return response;
  },
  CallRegisterUser: () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const response = useMutation((registerData) => RegisterUser(registerData), {
      onSuccess: (response) => {
        if (response?.status) {
          if (response?.data?.letterPreview === 1) {
            navigate(
              `/registration-complete/${response?.data?.newUser?.username}`,
              {
                replace: true,
                state: {
                  user: response?.data?.newUser?.username,
                },
              }
            );
          } else {
            toast.success(t("user_registered"));
            navigate(`/dashboard`);
          }
        } else if (response?.data?.code === 1009) {
          toast.error(response?.data?.description);
          navigate("/dashboard", { replace: true });
        } else {
          toast.error(response?.data?.description);
        }
      },
    });
    return response;
  },
  CallRegisterLink: (
    linkRegisterCheck,
    setLinkRegisterCheck,
    placement = "",
    position = "",
    isRegFromTree = 0
  ) => {
    let regFromTree = 0;
    // checking wheather reg From Tree or not
    if (isRegFromTree) {
      regFromTree = isRegFromTree;
    }
    const regFromTreePayload = {
      placement: placement,
      position: position,
      regFromTree: regFromTree,
    };
    const response = useQuery({
      queryKey: ["register-link"],
      queryFn: () => CreateRegisterLink(regFromTreePayload),
      onSuccess: () => {
        setLinkRegisterCheck(false);
        localStorage.clear();
      },
      enabled: !!linkRegisterCheck,
    });
    return response;
  },
  CallStoreLink: (storeLinkCheck, setStoreLinkCheck) => {
    const response = useQuery({
      queryKey: ["store-link"],
      queryFn: () => CreateStoreLink(),
      onSuccess: () => {
        setStoreLinkCheck(false);
        localStorage.clear();
      },
      enabled: !!storeLinkCheck,
    });
    return response;
  },
  CallBankUpload: (
    type,
    username,
    setSubmitButtonActive,
    setValue,
    setFileResponse
  ) => {
    const { t } = useTranslation();
    const response = useMutation((data) => BankUpload(data, username, type), {
      onSuccess: (res) => {
        if (res?.status) {
          setFileResponse({
            success: res?.data?.message,
          });
          setValue("bankReceipt", res?.data?.file?.filename);
          setSubmitButtonActive(false);
        } else {
          if (res?.data?.code === "1017") {
            setFileResponse({
              error: res?.data?.description,
            });
          } else if (res?.data?.code === "1018") {
            setFileResponse({
              error: res?.data?.description,
            });
          } else {
            setFileResponse({
              error: t("upload_failed"),
            });
          }
        }
      },
    });
    return response;
  },
  CallEwalletBalance: (getEwallet, setGetEwallet) => {
    const response = useQuery({
      queryKey: ["get-ewallet-balance"],
      queryFn: EwalletBalance,
      onSuccess: () => {
        setGetEwallet(false);
      },
      enabled: !!getEwallet,
    });
    return response;
  },
  CallLetterPreview: (username) => {
    const response = useQuery({
      queryKey: ["letter-preview"],
      queryFn: () => LetterPreview(username),
    });
    return response;
  },
  // ----------------------------------------- Tools ------------------------------------------
  CallGetFaqs: () => {
    const response = useQuery({
      queryKey: ["get-faqs"],
      queryFn: () => getFaqs(),
    });
    return response?.data?.data;
  },
  CallGetNews: (callApi) => {
    const response = useQuery({
      queryKey: ["all-news"],
      queryFn: () => getNews(),
    });
    return response?.data?.data;
  },
  CallGetNewsById: (newsId) => {
    const response = useQuery({
      queryKey: ["get-news-article", newsId],
      queryFn: () => getNewsById(newsId),
      enabled: !!newsId,
    });
    return response?.data?.data;
  },
  CallGetLeads: (page, itemsPerPage) => {
    const response = useQuery({
      queryKey: ["leads", page, itemsPerPage],
      queryFn: () => getLeads(page, itemsPerPage),
    });
    return response?.data?.data;
  },
  CallSearchLeads: () => {
    const resposne = useMutation((searchKey) => searchLeads(searchKey));
    return resposne;
  },
  CallUpdateLead: () => {
    const response = useMutation((data) => updateLead(data));
    return response;
  },
  CallGetReplicaBanner: () => {
    const response = useQuery({
      queryKey: ["get-replica-banner"],
      queryFn: () => getReplicaBanner(),
    });
    return response?.data;
  },
  CallUploadReplicaBanner: () => {
    const response = useMutation((data) => uploadReplicaBanner(data));
    return response;
  },
  CallGetDownloadMaterials: () => {
    const response = useQuery({
      queryKey: ["downloadable-material"],
      queryFn: () => getDownloadMaterials(),
    });
    return response.data;
  },

  // -------------------------------------------------- shopping ------------------------------------------------

  CallRepurchaseItems: () => {
    const response = useQuery({
      queryKey: ["repurchase-items"],
      queryFn: getRepurchaseItems,
    });
    return response;
  },
  CallAddToCart: () => {
    const response = useMutation((data) => addToCart(data));
    return response;
  },
  CallCartItems: (setShowCartItems = null) => {
    const { t } = useTranslation();
    const response = useQuery({
      queryKey: ["cart-items"],
      queryFn: getCartItems,
      onSuccess: (res) => {
        if (setShowCartItems) {
          setShowCartItems(true);
        }
      },
    });
    return response;
  },
  CallDecrementCartItem: () => {
    const response = useMutation((data) => decrementCartItem(data));
    return response;
  },
  CallRemoveCartItem: () => {
    const response = useMutation((data) => removeCartItem(data));
    return response;
  },
  CallAddAddress: () => {
    const response = useMutation((data) => addAddress(data));
    return response;
  },
  CallGetAddress: () => {
    const response = useQuery({
      queryKey: ["get-address"],
      queryFn: getAddress,
    });
    return response;
  },
  CallPaymentMethods: (action) => {
    const response = useQuery({
      queryKey: ["payment-methods"],
      queryFn: () => callPaymentMethods(action),
    });
    return response;
  },
  CallRemoveAddress: () => {
    const response = useMutation((data) => removeAddress(data));
    return response;
  },
  CallProductDetails: (id) => {
    const response = useQuery({
      queryKey: ["product-details"],
      queryFn: () => ProductDetails(id),
    });
    return response;
  },
  CallDefaultAddressChange: () => {
    const response = useMutation((id) => DefaultAddressChange(id));
    return response;
  },
  CallPlaceRepurchaseOrder: () => {
    const response = useMutation((data) => PlaceRepurchaseOrder(data));
    return response;
  },
  // -------------------------------------------------- Lcp ------------------------------------------------
  CallGetReplicaApi: (adminUsername) => {
    const response = useQuery({
      queryKey: ["get-api-key"],
      queryFn: () => getReplicaApi(adminUsername),
      onSuccess: (res) => {
        localStorage.setItem("apiKey", res?.apiKey);
      },
    });
    return response.data;
  },
  CallGetCompanyDetails: () => {
    const response = useQuery({
      queryKey: ["get-company-details"],
      queryFn: () => getCompanyDetails(),
      enabled: !!localStorage.getItem("apiKey"),
    });
    return response?.data?.data;
  },
  CallAddLcpLead: () => {
    const response = useMutation((body) => AddLcpLead(body));
    return response;
  },

  //  ------------------------------------------------- Replica ---------------------------------------------------------

  CallReplicaApiKey: (adminUsername, username, hashKey) => {
    const navigate = useNavigate();
    const response = useQuery({
      queryKey: ["replica-api-key"],
      queryFn: () => getApiKey(adminUsername),
      onSuccess: (res) => {
        if (res.status) {
          if (username || hashKey) {
            localStorage.setItem("referralId", username);
            localStorage.setItem("hashKey", hashKey);
            localStorage.setItem("apiKey", res?.data?.apiKey);
            localStorage.setItem("admin_user_name", adminUsername);
          }
        } else {
          if (res?.data?.code) {
            toast.error(res?.data?.description);
          }
          navigate("/login");
        }
      },
    });
    return response;
  },
  CallReplicaHome: () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const response = useQuery({
      queryKey: ["replica-home"],
      queryFn: ReplicaHome,
      onSuccess: (res) => {
        if (res?.status) {
          dispatch(setTermsAndPolicy(res?.data?.replicaHome));
          dispatch(setCompanyDetails(res?.data?.companyDetails));
          dispatch(setRegisterLink(res?.data?.registrationUrl));
          localStorage.setItem(
            "userLanguage",
            JSON.stringify({ code: res?.data?.langId })
          );
          i18n.changeLanguage(res?.data?.langId);
        } else {
          if (res?.data?.code) {
            toast.error(res?.data?.description);
          }
          navigate("/login");
        }
      },
    });
    return response;
  },
  CallReplicaRegisterFields: () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const response = useQuery({
      queryKey: ["replica-register-fields"],
      queryFn: ReplicaRegisterFields,
      onSuccess: (res) => {
        if (res?.status) {
          dispatch(setTermsAndPolicy(res?.data?.replicaTerms));
        } else {
          localStorage.clear();
          navigate("/login");
        }
      },
    });
    return response;
  },
  CallReplicaFieldCheck: (field, value, setFieldCheck, fieldCheck) => {
    const response = useQuery({
      queryKey: ["replica-register-field-check"],
      queryFn: () => replicaFieldCheck(field, value),
      onSuccess: () => {
        setFieldCheck(0);
      },
      enabled: !!fieldCheck,
    });
    return response;
  },
  CallReplicaBankRecieptUpload: (
    type,
    username,
    referralId,
    setSubmitButtonActive,
    setValue,
    setFileResponse
  ) => {
    const { t } = useTranslation();
    const response = useMutation(
      (data) => ReplicaBankUploadReceipt(data, username, referralId, type),
      {
        onSuccess: (res) => {
          if (res?.status) {
            setFileResponse({
              success: res?.data?.message,
            });
            setValue("bankReceipt", res?.data?.file?.filename);
            setSubmitButtonActive(false);
          } else {
            if (res?.data?.code === "1017") {
              setFileResponse({
                error: res?.data?.description,
              });
            } else if (res?.data?.code === "1018") {
              setFileResponse({
                error: res?.data?.description,
              });
            } else {
              setFileResponse({
                error: t("upload_failed"),
              });
            }
          }
        },
      }
    );
    return response;
  },
  CallReplicaRegisterPost: () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const hash = localStorage.getItem("hashKey");
    const referraiId = localStorage.getItem("referralId");
    const response = useMutation((data) => ReplicaRegisterPost(data), {
      onSuccess: (res) => {
        console.log(res, "response");
        if (res.status) {
          toast.success(t("user_registered"));
          navigate(`/replica/${referraiId}/${hash}`);
        } else if (res?.data?.code === 1009) {
          toast.error(res?.data?.description);
          navigate(`/replica/${referraiId}/${hash}`);
        } else {
          toast.error(res?.description);
        }
      },
    });
    return response;
  },
  CallAdminReplica: () => {
    const response = useQuery({
      queryKey: ["admin-replica"],
      queryFn:()=> getAdminReplica(),
    });
    console.log("response  v==== ",response?.data);
    return response?.data;
  },
  //  ------------------------------------------------- Upgrade & Renewal ---------------------------------------------------------
  CallGetUpgradeProducts: () => {
    const response = useQuery({
      queryKey: ["get-upgrade-products"],
      queryFn: UpgradeActions.getUpgradeProducts,
    });
    return response?.data;
  },
  CallGetSubscriptionDetails: () => {
    const response = useQuery({
      queryKey: ["get-subscription-details"],
      queryFn: RenewActions.getUpgradeProducts,
    });
    return response?.data;
  },
  CallRenewSubscription: () => {
    const response = useMutation((renewData) =>
      RenewActions.renewSubscription(renewData)
    );
    return response;
  },

  // ----------------------------------- Mailbox -------------------------------------------------
  CallInboxes: (page, limit) => {
    const response = useQuery({
      queryKey: ["inboxes"],
      queryFn: () => Inboxes(page, limit),
    });
    return response;
  },
  CallSingleMailDetails: (data, mailCheck, setMailCheck) => {
    const response = useQuery({
      queryKey: ["single-mail-details"],
      queryFn: () => SingleMail(data),
      onSuccess: () => {
        setMailCheck(false);
      },
      enabled: !!mailCheck,
    });
    return response;
  },
  CallReplyMail: () => {
    const response = useMutation((data) => ReplyMail(data));
    return response;
  },
};
