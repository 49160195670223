import React from "react";
import { useTranslation } from "react-i18next";

const MailSidebarContent = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="menu-bar">
        <ul className="menu-items">
          <li className="compose mb-3">
            <button
              data-bs-toggle="offcanvas"
              data-bs-target="#composemail"
              aria-controls="composemail"
              className="btn btn-primary btn-block"
              onClick={() => props.setShowCompose(true)}
            >
              {t("compose")}
            </button>
          </li>
          <li className="active">
            <a href="#">
              <i className="fa-regular fa-envelope-open"></i> {t("inbox")}
            </a>
            <span className="badge badge-pill bg-success">{props.count}</span>
          </li>
          <li>
            <a href="#">
              <i className="fa-regular fa-paper-plane"></i> {t("send")}
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa-solid fa-pen"></i> {t("admin_inbox")}
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa-solid fa-right-from-bracket"></i> {t("replica_inbox")}
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MailSidebarContent;
