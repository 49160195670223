import React, { useState } from "react";
import MailViewContent from "./MailViewContent";
import MailSidebarContent from "./MailSidebarContent";
import MailListContainerContent from "./MailListContainerContent";
import MailCompose from "./MailCompose";
import { ApiHook } from "../../hooks/apiHook";

const MailboxComponent = () => {
  const [showCompose, setShowCompose] = useState(false);
  const [singleMailId, setSingleMailId] = useState("");
  const [mailCheck, setMailCheck] = useState(false);
  const inboxes = ApiHook.CallInboxes(1, 10);

  return (
    <>
      <div className="content-wrapper">
        <div className="email-wrapper wrapper">
          <div className="row align-items-stretch">
            <div className="mail-sidebar d-none d-lg-block col-md-2 pt-3 bg-white">
              {/* Sidebar content */}
              <MailSidebarContent
                setShowCompose={setShowCompose}
                count={inboxes?.data?.inboxUnreadCount}
              />
            </div>
            <div className="mail-list-container col-md-3 pt-4 pb-4 border-right bg-white">
              {/* Mail list container content */}
              <MailListContainerContent
                inboxes={inboxes?.data?.inboxData?.data}
                setSingleMailId={setSingleMailId}
                setMailCheck={setMailCheck}
              />
            </div>
            <div className="mail-view col-md-9 col-lg-7 bg-white">
              {/* Mail view content */}
              <MailViewContent
                mailId={singleMailId}
                mailCheck={mailCheck}
                setMailCheck={setMailCheck}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Compose Mail */}
      <MailCompose showCompose={showCompose} setShowCompose={setShowCompose} />
    </>
  );
};

export default MailboxComponent;
