//local
// const BASE_URL = "http://192.168.21.19:5000/node_api/";
// const DEFAULT_KEY = "b8597a3a-1c41-40d0-a46b-3ef75e413616";

//demo
// const BASE_URL = "https://admin.luwak.iossmlm.com/node_api/";
// const DEFAULT_KEY = "b8597a3a-1c41-40d0-a46b-3ef75e413616";

//live
const BASE_URL = "https://admin.jayakopiluwak.com/node_api/";
const DEFAULT_KEY = "b8597a3a-1c41-40d0-a46b-3ef75e413616";

export { BASE_URL, DEFAULT_KEY};
