import React from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";

const MailCompose = ({showCompose, setShowCompose}) => {
  return (
    <>
      <Offcanvas
        id="composemail"
        show={showCompose}
        onHide={() => setShowCompose(false)}
        placement="end"
        style={{ backgroundColor: "white" }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>New Mail</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <main>
            <p className="text-center">New Message</p>
            <Form>
              <Form.Group className="mb-3" controlId="to">
                <Form.Label>To:</Form.Label>
                <Form.Control type="email" placeholder="Type email" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="cc">
                <Form.Label>CC:</Form.Label>
                <Form.Control type="email" placeholder="Type email" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="bcc">
                <Form.Label>BCC:</Form.Label>
                <Form.Control type="email" placeholder="Type email" />
              </Form.Group>
            </Form>
            <div className="row">
              <div className="col ml-auto">
                <div className="toolbar" role="toolbar">
                  <div className="btn-group">
                    <button type="button" className="btn btn-light">
                      <span className="fa fa-bold"></span>
                    </button>
                    <button type="button" className="btn btn-light">
                      <span className="fa fa-italic"></span>
                    </button>
                    <button type="button" className="btn btn-light">
                      <span className="fa fa-underline"></span>
                    </button>
                  </div>
                  <div className="btn-group">
                    <button type="button" className="btn btn-light">
                      <span className="fa fa-align-left"></span>
                    </button>
                    <button type="button" className="btn btn-light">
                      <span className="fa fa-align-right"></span>
                    </button>
                    <button type="button" className="btn btn-light">
                      <span className="fa fa-align-center"></span>
                    </button>
                    <button type="button" className="btn btn-light">
                      <span className="fa fa-align-justify"></span>
                    </button>
                  </div>
                  <div className="btn-group">
                    <button type="button" className="btn btn-light">
                      <span className="fa fa-indent"></span>
                    </button>
                    <button type="button" className="btn btn-light">
                      <span className="fa fa-outdent"></span>
                    </button>
                  </div>
                  <div className="btn-group">
                    <button type="button" className="btn btn-light">
                      <span className="fa fa-list-ul"></span>
                    </button>
                    <button type="button" className="btn btn-light">
                      <span className="fa fa-list-ol"></span>
                    </button>
                  </div>
                  <button type="button" className="btn btn-light">
                    <span className="fa fa-trash-o"></span>
                  </button>
                  <button type="button" className="btn btn-light">
                    <span className="fa fa-paperclip"></span>
                  </button>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-light dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <span className="fa fa-tags"></span>
                      <span className="caret"></span>
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                        add label{" "}
                        <span className="badge badge-danger"> Home</span>
                      </a>
                      <a className="dropdown-item" href="#">
                        add label <span className="badge badge-info"> Job</span>
                      </a>
                      <a className="dropdown-item" href="#">
                        add label{" "}
                        <span className="badge badge-success"> Clients</span>
                      </a>
                      <a className="dropdown-item" href="#">
                        add label{" "}
                        <span className="badge badge-warning"> News</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Form.Group className="mt-4">
              <Form.Control
                as="textarea"
                id="message"
                name="body"
                rows={12}
                placeholder="Click here to reply"
              />
            </Form.Group>
            <Form.Group className="mt-4">
              <Button variant="success" type="submit">
                Send
              </Button>
              <Button variant="light" type="submit">
                Draft
              </Button>
              <Button variant="danger" type="submit">
                Discard
              </Button>
            </Form.Group>
          </main>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MailCompose;
